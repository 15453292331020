import { createTheme } from '@material-ui/core/styles'
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TEXT_COLOR,
  DARK_AQUA_BLUE,
  FEED_POST_BORDER_COLOR,
  SEA_GREEN,
} from 'constants/colors'

const theme = createTheme({
  overrides: {
    MuiButton: {
      contained: {
        color: BUTTON_TEXT_COLOR,
        backgroundColor: BUTTON_BACKGROUND_COLOR,
        '&:hover': {
          backgroundColor: BUTTON_BACKGROUND_COLOR,
        },
        // borderRadius: 50,
      },
      text: {
        color: DARK_AQUA_BLUE,
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: SEA_GREEN,
      },
    },
    MuiPaper: {
      rounded: {
        // borderRadius: 16,
        borderColor: FEED_POST_BORDER_COLOR,
        border: '2px solid',
      },
    },
  },
})

export default theme
