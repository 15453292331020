import { useCallback } from 'react'

import { Timestamp, addDoc, collection, serverTimestamp } from 'firebase/firestore'
import { dbh } from 'config/firebaseConfig'

interface Action {
  userId: string
  timestamp: Timestamp | typeof serverTimestamp
  action: string
  userDisplayName?: string
  userEmail?: string
}

const useCreateAction = () => {
  const createAction = useCallback(async (action: Action) => {
    try {
      // Add a new document in the "actions" collection
      await addDoc(collection(dbh, 'actions'), action)
      console.log('Action created successfully')
    } catch (error) {
      console.error('Error creating action: ', error)
    }
  }, [])

  return createAction
}

export default useCreateAction
