/**
 * https://developers.google.com/identity/branding-guidelines
 */
import { useState } from 'react'

import clsx from 'clsx'

import { makeStyles } from '@material-ui/core'

import { FirebaseError } from 'firebase/app'
import { signInWithPopup, GoogleAuthProvider, signInWithRedirect } from 'firebase/auth'

import { auth } from 'config/firebaseConfig'
import { isPortrait } from 'utils/orientation'

import darkNormal from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png'
import darkFocus from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_focus_web@2x.png'
import darkPressed from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_pressed_web@2x.png'

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: '10rem', // actual max width
      width: '100%', // responsive width
      height: 'auto', // responsive height

      borderColor: 'solid red',
      borderWidth: '10px',
    },
  }),
  { name: 'SignInImageButtonDemo' }
)

export const SignInImageButtonDemo = (props: any) => {
  const classes = useStyles(props)

  const { className } = props

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [image, setImage] = useState(darkNormal)

  const handleSignIn = async () => {
    setLoading(true)

    try {
      if (isPortrait()) {
        await signInWithRedirect(auth, new GoogleAuthProvider())
      } else {
        await signInWithPopup(auth, new GoogleAuthProvider())
      }
    } catch (error) {
      // Catch errors if the user closes the login window
      if ((error as FirebaseError).code === 'auth/cancelled-popup-request') {
        setError('The authentication popup was closed before completion. Please try again.')
        console.error(error)
      } else {
        setError('An unexpected error occurred. Please try again.')
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <img
      className={clsx(classes.root, className)} // overrides, but only defaults apply
      onClick={handleSignIn}
      src={image}
      style={{
        cursor: 'pointer',
      }}
      alt="Google Sign in"
      onMouseEnter={() => setImage(darkFocus)}
      onMouseOut={() => setImage(darkNormal)}
      onMouseDown={() => setImage(darkPressed)}
      onMouseUp={() => setImage(darkFocus)}
    />
  )
}
