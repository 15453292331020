import { Outlet } from 'react-router-dom'

import { ThemeProvider, makeStyles } from '@material-ui/core/styles'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { GOOGLE_CLIENT_ID } from 'constants/google'

import theme from 'themes/defaultTheme'
import { useSupabaseAuth } from 'hooks/auth/useSupabaseAuth'

import { UserContext } from 'contexts/contexts'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'white',
    },
  }),
  { name: 'AppContainer' }
)

export const AppContainer = () => {
  const classes = useStyles()

  console.log('APP CONTAINER')

  const { session, user, loading, signOut } = useSupabaseAuth()

  console.log('CONTAINER > LOADING: ', loading)
  console.log('CONTAINER > SESSION: ', session)

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <UserContext.Provider value={{ loading, session, user, signOut }}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <Outlet />
          </GoogleOAuthProvider>
        </UserContext.Provider>
      </ThemeProvider>
    </div>
  )
}
