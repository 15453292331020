import { Session, User } from '@supabase/supabase-js'
import { createContext } from 'react'

export type UserContext = {
  // apiUser?: any
  // appUser?: any
  user: User | undefined
  session: Session | undefined
  loading: boolean
  signOut: () => Promise<void>
}

export const UserContext = createContext({} as UserContext)
export const LoginContext = createContext(value => {
  return value
})

export const AgencyContext = createContext(() => {})
