import { useEffect, useState } from 'react'

import _ from 'lodash'

import { User as FirebaseUser } from 'firebase/auth'
import { serverTimestamp } from 'firebase/firestore'

import { auth } from 'config/firebaseConfig'
import { DEFAULT_FIREBASE_USER } from 'constants/firebase'
import { UserService, updateUser } from 'services/UserService'
import { AppUser } from 'types/users'

const userService = new UserService()

type AuthData = {
  firebaseUser: FirebaseUser
  loading: boolean
  user: AppUser
}

export const useFirebaseAuth = (): AuthData => {
  const [loading, setLoading] = useState(true)
  const [firebaseUser, setFirebaseUser] = useState<FirebaseUser>(DEFAULT_FIREBASE_USER) // https://stackoverflow.com/a/69048952
  const [user, setUser] = useState<AppUser>({ id: '' })

  useEffect(() => {
    return auth.onAuthStateChanged(async user => {
      if (user) {
        console.log('USER SIGNED IN: ', user)
        let newUserProfile = await userService.fetchById(user.uid)
        const profile: AppUser = {
          id: user.uid || '',
          displayName: user.displayName || '',
          email: user.email || '',
          photoURL: user.photoURL || '',
          emailVerified: user.emailVerified || false,
          isAnonymous: user.isAnonymous || false,
        }

        if (!newUserProfile) {
          // INSERT
          const newUserId = user.uid
          console.log('NEW USER ID: ', newUserId)
          newUserProfile = await userService.createWithId(newUserId, {
            ...profile,
          })
        } else {
          // UPDATE
          console.log('UPDATING USER ID: ', user.uid, profile)
          updateUser(user.uid, { ...profile, updated: serverTimestamp() })
        }

        setUser(newUserProfile)
      } else {
        console.warn('USER SIGNED OUT')
        setUser(null)
      }

      setLoading(false)
    })
  }, [])

  return { firebaseUser, loading, user }
}
