import { grey } from '@material-ui/core/colors'

export const BURNT_ORANGE = 'rgb(240, 128, 26)'
export const DARK_AQUA_BLUE = 'rgb(23, 133, 182)'
export const SEA_GREEN = 'rgb(114, 195, 189)'
export const BEIGE = 'rgb(245, 220, 190)'
export const GRAY = 'rgb(128, 128, 128)'
export const LIGHTER_GRAY = 'rgb(225, 225, 225)'

export const FEED_POST_BORDER_COLOR = 'rgb(242, 242, 242)'
export const FEED_BACKGROUND_COLOR = 'rgb(247, 247, 247)'

// Official HTML color names
// https://www.w3schools.com/tags/ref_colornames.asp
export const WHITE_SMOKE = 'WhiteSmoke'

export const BUTTON_TEXT_COLOR = 'black'
export const BUTTON_BACKGROUND_COLOR = SEA_GREEN

export const FEED_SCROLLBAR_TRACK = grey[100]
export const FEED_SCROLLBAR_SLIDER = grey[200]
export const FEED_SCROLLBAR_SLIDER_HOVER = grey[300]
