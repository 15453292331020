/**
 * https://developers.google.com/identity/branding-guidelines
 */
import { useState } from 'react'

import { makeStyles } from '@material-ui/core'

import { FirebaseError } from 'firebase/app'
import {
  signInWithPopup,
  GoogleAuthProvider,
  signInWithRedirect,
  UserCredential,
} from 'firebase/auth'
import { Timestamp, serverTimestamp } from 'firebase/firestore'

import darkNormal from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_normal_web@2x.png'
import darkFocus from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_focus_web@2x.png'
import darkPressed from 'assets/google_signin_buttons/web/2x/btn_google_signin_dark_pressed_web@2x.png'

import { auth } from 'config/firebaseConfig'
import useCreateAction from 'hooks/useCreateAction'
import { isPortrait } from 'utils/orientation'

const useStyles = makeStyles(
  theme => ({
    root: {
      maxWidth: '10rem', // actual max width
      width: '100%', // responsive width
      height: 'auto', // responsive height
    },
  }),
  { name: 'SignInImageButton' }
)

export const SignInImageButton = (props: any) => {
  const classes = useStyles(props)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [image, setImage] = useState(darkNormal)

  const createAction = useCreateAction()

  const handleSignIn = async () => {
    setLoading(true)

    try {
      let userCredentials: UserCredential = {} as UserCredential
      if (isPortrait()) {
        userCredentials = await signInWithRedirect(auth, new GoogleAuthProvider())
      } else {
        userCredentials = await signInWithPopup(auth, new GoogleAuthProvider())
      }

      // Extract the user information
      const { user } = userCredentials

      // Create an action for the user login in the database
      await createAction({
        userId: user?.uid || '',
        timestamp: serverTimestamp() as Timestamp,
        action: 'login',
        userDisplayName: user?.displayName || '',
        userEmail: user?.email || '',
      })
    } catch (error) {
      // Catch errors if the user closes the login window
      if ((error as FirebaseError).code === 'auth/cancelled-popup-request') {
        setError('The authentication popup was closed before completion. Please try again.')
        console.error(error)
      } else {
        setError('An unexpected error occurred. Please try again.')
        console.error(error)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <img
      className={classes.root} // overrides, but only defaults apply
      onClick={handleSignIn}
      src={image}
      style={{
        cursor: 'pointer',
      }}
      alt="Google Sign in"
      onMouseEnter={() => setImage(darkFocus)}
      onMouseOut={() => setImage(darkNormal)}
      onMouseDown={() => setImage(darkPressed)}
      onMouseUp={() => setImage(darkFocus)}
    />
  )
}
