import { useState, useEffect } from 'react'
import { supabase } from 'config/supabaseConfig'
import type { Session, User } from '@supabase/supabase-js'

export const useSupabaseAuth = () => {
  const [session, setSession] = useState<Session | undefined>(undefined)
  const [user, setUser] = useState<User | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getSession = async () => {
      // Set initial session
      const initialSession = await supabase.auth.getSession()
      setSession(initialSession.data.session || undefined)
      setUser(initialSession.data.session?.user)

      const { data: authListener } = supabase.auth.onAuthStateChange(async (event, newSession) => {
        if (newSession) {
          console.log('SUPABASE AUTH STATE CHANGE > newSession: ', newSession)
          setSession(newSession)
          setUser(newSession.user)
        } else {
          setSession(undefined)
          setUser(undefined)
        }
        setLoading(false)
      })

      // Cleanup: Unsubscribe from the listener
      return () => {
        authListener.subscription.unsubscribe()
      }
    }
    getSession()
  }, [])

  const signOut = async () => {
    await supabase.auth.signOut()
    setSession(undefined)
    setUser(undefined)
  }

  return { session, user, loading, signOut }
}
