// https://github.com/DavidWells/next-with-react-router-v6#route-switcher

import { ErrorBoundary } from 'react-error-boundary'

import { makeStyles } from '@material-ui/core/styles'

import { lazyImport } from 'utils/importing'
import ReloadButton from 'components/ReloadButton'
import { MyErrorBoundaryComponent } from 'errors/MyFallbackComponent'
import { CircularProgress } from '@material-ui/core'
import { UserContext } from 'contexts/contexts'
import { useContext } from 'react'

const { PublicRoutes } = lazyImport(() => import('./PublicRoutes'), 'PublicRoutes')
const { PrivateRoutes } = lazyImport(() => import('./PrivateRoutes'), 'PrivateRoutes')

const useStyles = makeStyles(
  theme => ({
    loading: {
      display: 'flex',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'MultiLineTextField' }
)

export const AppRoutes = () => {
  const classes = useStyles()
  const { session, user, loading } = useContext(UserContext)

  // console.log('APP ROUTES > APP USER: ', appUser)
  console.log('APP ROUTES > LOADING: ', loading)
  console.log('APP ROUTES > USER: ', user)
  console.log('APP ROUTES > SESSION: ', session)

  return loading ? (
    <div className={classes.loading}>
      <CircularProgress />
    </div>
  ) : session ? (
    // <MyErrorBoundaryComponent>
    //   <PrivateRoutes user={user} loading={loading} />
    // </MyErrorBoundaryComponent>
    <PrivateRoutes />
  ) : (
    // <ErrorBoundary fallback={<div>Something went wrong: Public</div>}>
    //   <PublicRoutes />
    // </ErrorBoundary>
    <PublicRoutes />
  )
}
