import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import { Container, Paper } from '@material-ui/core'

import { useFirebaseAuth } from 'hooks/auth/useFirebaseAuth'

import { SignInImageButton } from 'components/SignInImageButton'

import { APP_SLOGAN } from 'constants/app'
import { BURNT_ORANGE, DARK_AQUA_BLUE, SEA_GREEN } from 'constants/colors'
import { FEED_PATH } from 'constants/navigation/mainNav'
import { MODAL_BORDER_RADIUS_SPACING } from 'constants/styling'
import { SignInImageButtonDemo } from './SignInImageButtonDemo'

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flex: 1,
      fontFamily: 'Rubik, sans-serif',
      justifyContent: 'center',
      background: `linear-gradient(135deg, ${SEA_GREEN}, ${DARK_AQUA_BLUE})`,
    },

    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(2),
      justifyContent: 'center',
      alignItems: 'center',
    },

    paper: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      borderRadius: theme.spacing(MODAL_BORDER_RADIUS_SPACING),
    },

    header: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: theme.spacing(2),
    },

    body: {
      display: 'flex',
      flex: 1,
      padding: theme.spacing(2),
      justifyContent: 'center',
    },

    // Header contents
    appName: {
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '3rem',
      color: 'rgb(114, 195, 189)', // light blue
    },

    appSlogan: {
      color: BURNT_ORANGE,
      margin: theme.spacing(2),
      fontWeight: 'bold',
      fontSize: '1.2rem',
    },

    buttons: {
      display: 'flex',
      flexDirection: 'column',
    },

    joinNowButton: {},

    signInButton: {
      marginLeft: theme.spacing(4),
    },

    // Body contents
    welcome: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    splashImage: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },

    imageButton: {
      maxWidth: '12rem', // actual max width
      width: '100%', // responsive width
      height: 'auto', // responsive height

      border: '5px solid gold',
    },
  }),
  { name: 'SimpleHomepage' }
)

export const SimpleHomepage = (props: any) => {
  const classes = useStyles(props)

  const navigate = useNavigate()
  const { user, loading } = useFirebaseAuth()

  if (user) {
    navigate(FEED_PATH)
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.header}>
            <div className={classes.appName}>ShiftStack</div>
            <div className={classes.appSlogan}>Simple Homepage Demo</div>
          </div>
          <div className={classes.body}>
            <div className={classes.buttons}>
              <SignInImageButtonDemo className={classes.imageButton} />
            </div>
          </div>
        </Paper>
      </Container>
    </div>
  )
}
