import { dbh } from 'config/firebaseConfig'
import { DocumentData, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'

import { Service } from './Service'
import { USERS_COLLECTION } from 'constants/firestore'
import { UserData } from 'types/firestoreDocumentTypes'

export class UserService extends Service {
  constructor() {
    super(USERS_COLLECTION)
  }
}

export const updateUser = async (userId: string, data: UserData) => {
  try {
    const userRef = doc(dbh, USERS_COLLECTION, userId) // Assuming your collection is named "Users"
    await updateDoc(userRef, data)
  } catch (error) {
    console.error('Error updating user:', error)
    throw error
  }
}

let cachedUsers: UserData[] | null = null // cache storage

export const fetchUsersWithPhoto = async (): Promise<UserData[]> => {
  // If we have a cached result, return it.
  if (cachedUsers !== null) {
    console.log('CACHED HIT - RETURNING CACHED USERS')
    return cachedUsers
  }

  console.log('FETCHING USERS WITH PHOTO')

  // Reference to the users collection
  const usersCollectionRef = collection(dbh, USERS_COLLECTION)

  // Create a query to fetch users with a valid photoUrl
  const q = query(usersCollectionRef, where('photoURL', '!=', ''))

  const querySnapshot = await getDocs(q)

  const users: DocumentData[] = []
  querySnapshot.forEach(doc => {
    users.push({
      id: doc.id,
      ...doc.data(),
    })
  })

  // Cache the result
  console.log('CACHING USERS')
  cachedUsers = users

  return users
}
