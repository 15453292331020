import { User as FirebaseUser } from 'firebase/auth'

// Empty default Firebase user object
export const DEFAULT_FIREBASE_USER: FirebaseUser = {
  uid: '',
  displayName: '',
  email: '',
  emailVerified: false,
  isAnonymous: false,
  photoURL: '',
  providerData: [],
  getIdToken: async () => '',
  getIdTokenResult: async () => ({
    token: '',
    expirationTime: '',
    authTime: '',
    issuedAtTime: '',
    signInProvider: '',
    claims: {},
    signInSecondFactor: '',
  }),
  reload: async () => {},
  delete: async () => {},
  toJSON: async () => '',
  metadata: {
    creationTime: '',
    lastSignInTime: '',
  },
  phoneNumber: '',
  providerId: '',
  refreshToken: '',
  tenantId: '',
}
