import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import { AppRoutes } from './routes/AppRoutes'
import { AppContainer } from 'containers/AppContainer'
import { FirebaseSignIn } from 'examples/FirebaseSignIn'
import { FirebaseSignInWithImageButton } from 'examples/FirebaseSignInWithImageButton'
import { ReactOauthGoogleSimple } from 'examples/ReactOauthGoogleSimple'
import { SimpleHomepage } from 'examples/SimpleHomepage'

// makeStyles (new) vs createStyles (old)
// https://smartdevpreneur.com/material-ui-makestyles-usestyles-createstyles-and-withstyles-explained/
const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'grid',
      height: '100vh',
      width: '100vw',
      backgroundColor: 'white',
    },
  }),
  { name: 'App' }
)

const App = () => {
  const classes = useStyles()

  console.warn('APP')
  return (
    <div className={classes.root}>
      <Router>
        <Routes>
          <Route path="/*" element={<AppContainer />}>
            {/* <Route path="/homepage" element={<Homepage />} /> */}

            {/* Examples for testing new libraries */}
            <Route path="examples">
              <Route path="firebase" element={<FirebaseSignIn />} />
              <Route path="firebase-image" element={<FirebaseSignInWithImageButton />} />
              <Route path="google" element={<ReactOauthGoogleSimple />} />
              <Route path="homepage" element={<SimpleHomepage />} />
            </Route>

            {/* Protected and Public Routes start here */}
            {/* <Route path="*" element={<AppRoutes />} /> */}
            {/* <Route index element={<AppRoutes />} /> */}
            <Route path="*" element={<AppRoutes />} />

            {/* Landing page goes here -- should be in Public Routes, but whatever, it works */}
            {/* <Route index element={<Homepage />} /> */}
            {/* <Route index element={<HomepageSimple />} /> */}
          </Route>
        </Routes>
      </Router>
    </div>
  )
}

export default App
