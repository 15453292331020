import HomeIcon from '@material-ui/icons/Home'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'

import ExploreIcon from '@material-ui/icons/Explore'
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined'

import NotificationsIcon from '@material-ui/icons/Notifications'
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined'

import MessageIcon from '@material-ui/icons/Message'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'

import AddIcon from '@material-ui/icons/Add'
import AddOutlinedIcon from '@material-ui/icons/AddOutlined'
import { SEA_GREEN } from '../colors'

// Settings Imports
import { NavItems } from 'types/nav'

export const FEED_SLUG = 'feed'
export const EXPLORE_SLUG = 'explore'
export const NOTIFICATIONS_SLUG = 'notifications'

export const FEED_PATH = `/${FEED_SLUG}`
export const EXPLORE_PATH = `/${EXPLORE_SLUG}`
export const NOTIFICATIONS_PATH = `/${NOTIFICATIONS_SLUG}`
export const MESSAGES_PATH = '/messages'
export const POST_PATH = '/post'
export const ROOT_PATH = '/'

export const MAIN_VERTICAL_MENU_NAV: NavItems = [
  {
    label: 'Home',
    path: ROOT_PATH,
    icon: HomeIcon,
    outlined: HomeOutlinedIcon,
  },
  {
    label: 'Explore',
    path: EXPLORE_PATH,
    icon: ExploreIcon,
    outlined: ExploreOutlinedIcon,
  },
  {
    label: 'Notifications',
    path: NOTIFICATIONS_PATH,
    icon: NotificationsIcon,
    outlined: NotificationsOutlinedIcon,
  },
  {
    label: 'Messages',
    path: MESSAGES_PATH,
    icon: MessageIcon,
    outlined: MessageOutlinedIcon,
  },
  {
    label: 'Post',
    path: '/post',
    icon: AddIcon,
    outlined: AddOutlinedIcon,
    color: 'black',
    backgroundColor: SEA_GREEN,
    justifyContent: 'center',
    hoverFilter: 'brightness(90%)',
  },
]
